import http from '@/common/utils/http'
const RJURL = 'RuiJin'

/** 获取所属项目列表 */
export function reqElevatorProjectSelectList() {
  return http({
    url: `pm/project/searchElevatorProjectSelectList`,
    method: "get",
  });
}

/** 获取小区列表 */
export function villageCount(data) {
  return http({
    url: `wuye/villagesCount`,
    method: 'post',
    data
  })
}

/** 获取电梯状态 */
// export function villagesCountRate(data) {
//   return http({
//     url: `maixin/wuye/villagesCountRate`,
//     method: 'post',
//     data
//   })
// }
export function villagesCountRate(data) {
  return http({
    url: `maixin/wuye/villagesCountRateCQ`,
    method: 'post',
    data
  })
}



/** 获取电梯列表 */
export function getElevatorsStatus(data) {
  return http({
    url: `RuiJin/getElevatorsStatus`,
    method: 'post',
    data
  })
}




/** 获取小区下拉框 */
export function searchVillageSelectList(data) {
  return http({
    url: `village/searchVillageSelectList`,
    method: 'post',
    data
  })
}

export function getElevatorHlsInfo(data) {
  return http({
    url: `${RJURL}/getElevatorHlsInfo`,
    method: 'post',
    data
  })
}

/** 获取不文明行文列表 */
export function searchUncivilizedltList(data) {
  return http({
    url: `fault/searchFaultList`,
    method: 'post',
    data
  })
}

/** 获取不文明行文列表 */
export function searchFaultList(data) {
  return http({
    url: `fault/searchFaultList`,
    method: 'post',
    data
  })
}

/** 获取不文明行文列表 */
export function searchMaiXinFaultList(data) {
  return http({
    url: `maixin/wuye/searchFaultList`,
    method: 'post',
    data
  })
}

/** 获取电梯数量  */
export function countElevator(data) {
  return http({
    // url: `${RJURL}/countElevator/${data}`,
    // method: 'get'
    url: `wuye/getUserElevatorCount`,
    method: 'post'
  })
}

/** 获取故障详情 */
export function getFaultDetail(data) {
  return http({
    url: `fault/getFaultDetail/${data}`,
    method: 'get'
  })
}

/** 获取不文明行文列表 */
export function getHistoryRecordCount(data) {
  return http({
    url: `commonBigScreen/fault/getHistoryRecordCount`,
    method: 'post',
    data
  })
}

// 智能监管
// export function intelligentSupervision(data) {
//   return http({
//     url: `wuye/IntelligentSupervision`,
//     method: 'post',
//     data
//   })
// }

export function intelligentSupervision(data) {
  return http({
    url: `maixin/wuye/IntelligentSupervisionCQ`,
    method: 'post',
    data
  })
}

/** 最新困人  */
export function personShuttingLately(data) {
  return http({
    url: `${RJURL}/personShuttingLately`,
    method: 'post',
    data
  })
}

/** 电梯状态 */
// export function getElevatorsStatus(data) {
//   return http({
//     url: `${RJURL}/getElevatorsStatus`,
//     method: 'post',
//     data
//   })
// }
/** 获取小区列表 */
export function searchBuildingList(data) {
  return http({
    url: `building/search`,
    method: 'post',
    data
  })
}

export function searchElevatorNotPage(data) {
  return http({
    url: `RuiJin/searchElevatorNotPage`,
    method: 'post',
    data
  })
}



