<template>
    <div class="layout">
        <div class="siderbar-wrapper" :style="{ width: this.isCollapsed ? '64px' : '200px' }">
             <!-- <div v-if="user && user.user.customized" class="logo-wrapper_s2" style="height:150px">
                <img src="../../assets/msdt.png" alt="">
            </div> -->
              <div class="logo-wrapper" :style="{ 'background-image':theme =='light'?'radial-gradient(#fff 1%,#2d8cf0 60%)':'radial-gradient(#fff 1%,#0d1d4d 60%)'}">
                <img src="../../assets/log2.png" alt="">
            </div>

            <div :class="this.isCollapsed ? 'hideSidebar' : ''">
                <div class="sidebar-container">
                    <el-scrollbar wrap-class="scrollbar-wrapper">
                        <el-menu :background-color="theme =='light'?'#2d8cf0':'#0d1d4d'" text-color="#fff" :default-active="activeMenu" :collapse="this.isCollapsed" :unique-opened="false" :collapse-transition="false" mode="vertical">
                            <sidebar-item v-for="route in permission_routes" :key="route.path" :item="route" :base-path="route.path" />
                        </el-menu>
                    </el-scrollbar>
                </div>
            </div>
        </div>
        <div class="topbar-wrapper" :style="{ left: this.isCollapsed ? '64px' : '200px' }">
            <div class="menu-collapse-wrapper float-left" @click="toggleMenu">
                <i class="el-icon-adm-menu" :style="{
            transform: 'rotateZ(' + (this.isCollapsed ? '90' : '0') + 'deg)'
          }"></i>
            </div>
            <div class="title float-left">当前位置：{{ $store.state.title }}</div>
            <ul class="menu-list float-right">
                <li class="menu-item">
                    <el-dropdown @command="handleCommand">
                        <span class="el-dropdown-link">
                            {{theme == 'light'?'浅色':'深色'}}<i class="el-icon-arrow-down el-icon--right"></i>
                        </span>
                        <el-dropdown-menu slot="dropdown">
                            <el-dropdown-item command="light">浅色</el-dropdown-item>
                            <el-dropdown-item command="dark">深色</el-dropdown-item>
                        </el-dropdown-menu>
                    </el-dropdown>
                </li>
                <li class="menu-item" @click="exit">
                    <i class="icon iconfont el-icon-adm-exit"></i>
                </li>
            </ul>
            <!-- <div class="name">长兴镇城运中心智慧电梯管理平台</div> -->
            <div class="name">生态城投智慧电梯管理平台</div>
        </div>
        <div class="content-wrapper" ref="content-wrapper" :style="{ left: this.isCollapsed ? '64px' : '200px' }">
            <div class="content">
                <router-view></router-view>
            </div>
        </div>
    </div>
</template>
<script>
import SidebarItem from '@/components/Sidebar/SidebarItem'
// import variables from '@/common/styles/variable.less'
import { sessionStorage } from '../../common/utils/storage'
import { mapGetters } from 'vuex'
export default {
    created() {
        // this.checkAuth()
    },
    data() {
        return {
            isCollapsed: false,
            adminMenuShow: false,
            title: this.$store.state.user
        }
    },
    components: { SidebarItem },
    computed: {
        ...mapGetters(['permission_routes']),

        theme() {
            return this.$store.getters.theme
        },
        user() {
            return this.$store.state.user
        },
        activeMenu() {
            const route = this.$route
            const { meta, path } = route
            // if set path, the sidebar will highlight the path you set
            if (meta.activeMenu) {
                return meta.activeMenu
            }
            return path
        }
    },
    methods: {
        checkAuth() {
            let token = this.$store.state.token || sessionStorage.getItem('token')
            if (!token) {
                this.$router.replace('/login')
            }
        },
        handleCommand(command) {
            this.$store.dispatch('settings/changeTheme', {
                'theme': command
            })
        },
        toggleMenu() {
            this.isCollapsed = !this.isCollapsed
        },
        exit() {
            this.$confirm('即将退出系统登陆，是否继续？', '提示', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    type: 'warning'
                })
                .then(() => {
                    this.$store.dispatch('user/logout')
                    // this.$store.commit('SET_TOKEN', '')
                    // this.$store.commit('SET_USER', '')
                    this.$router.replace({ path: '/login' })
                })
                .catch(() => {
                    return false
                })
        }
    }
}
</script>
<style lang="scss" scoped>
@import "@/common/styles/handle.scss";

.layout {
    .siderbar-wrapper {
        position: fixed;
        top: 0;
        bottom: 0;
        left: 0;
        width: $sideBarWidth;
        z-index: 11;
        transition: all 0.3s ease-in-out;

        .logo-wrapper {
            padding: 16px 0;
            display: flex;
            align-items: center;
            justify-content: center;

            img {
                width: 70px;
                // height: 30px;
            }
        }

        .logo-wrapper_s2 {
            padding: 16px 0;
            display: flex;
            align-items: center;
            justify-content: center;

            img {
                width: 90px;
                // height: 30px;
              box-shadow: 0 0 15px 15px rgb(253, 252, 252) 
            }
        }

        .menu-wrapper {
            position: absolute;
            top: 72px;
            bottom: 0;
            width: 100%;
            border-right: none;
            transition: all 0.3s ease-in-out;

            &:not(.el-menu--collapse) {
                overflow-y: auto;
                overflow-x: hidden;
            }

            i {
                color: #ffffff;
            }

            .menu-item {

                &.is-active,
                &:hover {
                    background-color: #353f4f !important;
                    color: #65cea7 !important;

                    i {
                        color: #65cea7 !important;
                    }
                }
            }

            .el-submenu__title:hover {
                color: #65cea7 !important;

                i {
                    color: #65cea7 !important;
                }
            }

            .el-submenu,
            .el-menu-item {
                color: red !important;
                width: 100%;
            }
        }

        @include background_color(siderbarBackgroundColor);
    }

    .topbar-wrapper {
        position: fixed;
        left: $sideBarWidth;
        right: 0;
        top: 0;
        height: $topbarHeight;
        line-height: $topbarHeight;
        padding: 0 24px 0 0;
        @include background_color(topBarWraperBgColor);
        box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.12), 0 0 6px 0 rgba(0, 0, 0, 0.04);
        transition: all 0.3s ease-in-out;
        z-index: 12;

        .name {
            position: absolute;
            @include font_color(topBarTitleTextColor);
            font-size: 26px;
            font-weight: 700;
            left: 50%;
            top: 50%;
            transform: translate(-50%, -50%);
        }

        .menu-collapse-wrapper {
            height: 100%;
            width: 48px;
            text-align: center;
            cursor: pointer;

            i {
                transition: all 0.3s ease-in-out;
            }
        }

        .title {
            height: 100%;
            font-weight: bold;
            @include font_color(locationTitleTextColor);
        }

        .menu-list {
            .menu-item {
                position: relative;
                float: left;
                padding: 0 15px;
                min-width: 45px;
                height: 48px;
                text-align: center;
                font-size: 0px;

                &:hover {
                    cursor: pointer;
                    background-color: #f5f5f5;
                }

                .icon {
                    vertical-align: middle;
                    font-size: 24px;
                }

                .text {
                    display: inline-block;
                    vertical-align: middle;
                    margin-left: 4px;
                    font-size: 16px;
                }
            }
        }
    }

    .content-wrapper {
        position: fixed;
        left: $sideBarWidth;
        right: 0;
        top: $topbarHeight;
        bottom: 0;
        // padding: 10px;
        overflow: auto;
        transition: all 0.3s ease-in-out;
        @include background_color(layoutContentWrapperBgColor);

        .content {
            position: relative;
            width: 100%;
            height: 100%;
        }
    }

    ::v-deep .el-menu-item.is-active {
        @include background_color(menuItemAcitiveBgColor);
        @include font_color(menuItemAcitiveTextColor);
    }

    .el-submenu__icon-arrow.el-icon-arrow-down {
        color: #fff;
    }

}
</style>